import React, { useState, useEffect, useCallback } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "../column";
import initialData from "../initial-data";
import useDragDrop from "./hooks/useDragDrop"; // Import the hook
import drafting from "../../../../services/drafting";
import AddButton from "./AddButton";
import Uploader from "../../../file_mgmt/uploader/Uploader";
import useAlbumInfo from "./hooks/useAlbumInfo";
import useTrackManager from "./hooks/useTrackManager";
import useFileAndDraftSubscriptions from "./hooks/useFileAndDraftSubscriptions";

const CreateAlbumView = ({ album }) => {
  const [data, setData] = useState(initialData);
  const { artistId } = useAlbumInfo(album.docId);
  const addNewTrack = useTrackManager(album, artistId, data, setData);
  useFileAndDraftSubscriptions(album, setData);

  const { onDragStart, onDragEnd, homeIndex } = useDragDrop(
    data,
    album,
    setData,
    artistId
  );

  return (
    <div className="flex flex-col md:flex-row gap-4 p-4">
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {data.columnOrder.map((columnId, index) => {
          const column = data.columns[columnId];
          const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
          const isDropDisabled = index < homeIndex;

          // Determine the width of the column based on index
          const widthClass = index === 0 ? "w-1/3" : "w-2/3";

          return (
            <div
              key={column.id}
              className={`flex flex-col ${widthClass} ${
                index === 0 ? "mr-2" : "ml-2"
              }`}
            >
              {index === 0 && (
                <div className="font-theFutureMonoRegular text-lg text-yellow-200 p-4 ">
                  {"/files"}
                </div>
              )}
              {index === 1 && (
                <AddButton
                  label="track+"
                  onClick={addNewTrack}
                  className="self-start px-5 mb-2"
                />
              )}
              {index === 0 && <Uploader />}
              <Column
                column={column}
                tasks={tasks}
                album={album}
                isDropDisabled={isDropDisabled}
              />
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
};

export default CreateAlbumView;
