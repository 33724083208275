import React, { useState, useEffect } from "react";
import "firebase/compat/auth";
import CreateAlbumView from "./album_creator/CreateAlbumView";
import { useLocation, useHistory } from "react-router-dom";

import AlbumDraftImageUploader from "./album_creator/views/images/AlbumDraftImageUploader";
import EditAlbumMetadataView from "./EditAlbumMetadataView";
import SetAlbumArtistView from "./album_creator/views/SetAlbumArtistView";
import ModularSidebar from "../views/ModularSidebar";
import WideButton from "../../buttons/WideButton";
import LightSpinner from "../../loading/LightSpinner";
import DeleteAlbumButton from "./DeleteAlbumButton";
import useAlbumLoader from "./hooks/useAlbumLoader";

function DraftEditView() {
  let location = useLocation();
  let history = useHistory();

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const { album, isLoading, finalize, loadAlbum } = useAlbumLoader(
    {},
    history,
    location
  );

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    loadAlbum();
  }, [loadAlbum]);

  useEffect(() => {
    const checkIfLargeScreen = () => {
      setSidebarOpen(window.innerWidth >= 768);
    };

    checkIfLargeScreen();
    window.addEventListener("resize", checkIfLargeScreen);
    return () => window.removeEventListener("resize", checkIfLargeScreen);
  }, []);
  const SHOW_SIDEBAR = false; // Set to false to hide the sidebar

  return (
    <div className="flex h-screen pb-5">
      {SHOW_SIDEBAR && (
        <ModularSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      )}
      <div
        className={`flex-1 flex flex-col ${
          SHOW_SIDEBAR && isSidebarOpen ? "lg:ml-64" : ""
        }`}
      >
        <main className="flex-grow p-4 overflow-auto">
          {isLoading ? (
            <div className="flex items-center justify-center p-10">
              <LightSpinner></LightSpinner>
            </div>
          ) : (
            <div className="container pb-16 mx-auto flex flex-col items-center justify-center">
              <AlbumDraftImageUploader album={album} />
              <EditAlbumMetadataView album={album} />
              <DeleteAlbumButton album={album} />
              <SetAlbumArtistView albumId={album?.docId} />
              <CreateAlbumView album={album} />
              <WideButton lambda={() => finalize()} text={"next"}></WideButton>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default DraftEditView;
