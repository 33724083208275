// SignInButton.jsx
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const SignInButton = () => {
  const history = useHistory();
  const location = useLocation();

  const signIn = () => {
    // Instead of sessionStorage, directly pass the location to the sign-in route state
    history.push('/signin', { from: location });
  };

  return (
    <button onClick={signIn} className="text-blue-600 hover:text-blue-800">
      Sign in
    </button>
  );
};

export default SignInButton;
