import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { usePhotoDetail } from "../../file_mgmt/drive/hooks/usePhotoDetail";
import PhotoNavigation from "./PhotoNavigation";
import useEventMemories from "../../file_mgmt/drive/hooks/useEventStorageHooks";
import usePreloadAdjacentImages from "./usePreloadAdjacentImages";
import LightSpinner from "../../loading/LightSpinner";
import LoadingOrError from "./LoadingOrError";
import ImageDisplay from "./ImageDisplay";
import BackToEventButton from "../../buttons/BackToEventButton";
const PhotoDetailView = () => {
  const { id: eventId, photoId: memoryId } = useParams();
  const history = useHistory();
  const {
    photo,
    loading: loadingPhoto,
    error: errorPhoto,
  } = usePhotoDetail(eventId, memoryId);
  const {
    memories,
    loading: loadingMemories,
    error: errorMemories,
  } = useEventMemories(eventId);
  const isLoading = loadingPhoto || loadingMemories;
  const hasError = !!errorPhoto || !!errorMemories;

  const currentIndex = useMemo(
    () => memories.findIndex((memory) => memory.id === memoryId),
    [memories, memoryId]
  );
  usePreloadAdjacentImages(currentIndex, memories);

  const navigateToPhoto = useCallback(
    (newIndex) => {
      const newMemoryId = memories?.[newIndex]?.id;
      if (newMemoryId) {
        history.push(`/events/${eventId}/photos/${newMemoryId}`);
      }
    },
    [memories, history, eventId]
  );

  const handleArrowKeyNavigation = useCallback(
    (event) => {
      if (event.key === "ArrowLeft") {
        navigateToPhoto(currentIndex - 1);
      } else if (event.key === "ArrowRight") {
        navigateToPhoto(currentIndex + 1);
      }
    },
    [currentIndex, navigateToPhoto]
  );

  const goBackToEvent = () => {
    history.push(`/e/${eventId}`); // Navigate back to the main event page
  };

  useEffect(() => {
    window.addEventListener("keydown", handleArrowKeyNavigation);
    return () =>
      window.removeEventListener("keydown", handleArrowKeyNavigation);
  }, [handleArrowKeyNavigation]);

  const errorMessage = errorPhoto?.message || errorMemories?.message;

  return (
    <div className="relative pb-16 md:pb-20">
      <BackToEventButton eventId={eventId} />
      <LoadingOrError
        isLoading={isLoading}
        hasError={hasError}
        error={errorMessage}
      />
      {!isLoading && !photo && <div>Memory not found</div>}
      {photo && (
        <ImageDisplay
          photo={photo}
          currentIndex={currentIndex}
          memories={memories}
          navigateToPhoto={navigateToPhoto}
        />
      )}
      {photo && (
        <PhotoNavigation
          currentIndex={currentIndex}
          totalCount={memories.length}
          onNavigate={navigateToPhoto}
        />
      )}
    </div>
  );
};

export default PhotoDetailView;
