// ProfileContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useFetchWithAuth } from '../../../../data layer/useFetchWithAuth';
import APIConfig from '../../../../APIConfig';

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const profileUrl = `${APIConfig.me()}`;
  const { data: profileInfo, loading, error } = useFetchWithAuth(profileUrl, { method: "GET" });
  const value = { profileInfo, loading, error };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
