import { useState, useCallback } from 'react';
import storage from "../../../../services/storage";
import drafting from "../../../../services/drafting";

const useAlbumLoader = (initialAlbum, history, location) => {
  const [album, setAlbum] = useState(initialAlbum);
  const [isLoading, setLoading] = useState(true);
  const [tracks, setTracks] = useState([]);
  const [artistId, setArtistId] = useState(0);

  const finalize = useCallback(async () => {
    const _album = await storage.validateDraft(album);
    if (_album.isValid) {
      history.push({ pathname: "/publish/album", album: _album });
    } else {
      alert("Finish creating the album first");
    }
  }, [album, history]);

  const loadDraft = useCallback(async (docId) => {
    const album_draft = await drafting.getDraft(docId);
    setArtistId(album_draft.artist_id);
    const tracksArray = Object.values(album_draft.tracks).map((song) => ({
      title: song.track_name,
      artist: album_draft.artist,
      audioSrc: song.url,
    }));
    setTracks(tracksArray);
    setAlbum(album_draft);
  }, []);

  const loadAlbum = useCallback(async () => {
    if (!location.album) {
      const draft = await storage.getRecentDrafts();
      await loadDraft(draft.docId);
    } else {
      setAlbum(location.album);
      await loadDraft(location.album.docId);
    }
    setLoading(false);
  }, [location.album, loadDraft]);

  return { album, isLoading, tracks, artistId, finalize, loadAlbum };
};

export default useAlbumLoader;
