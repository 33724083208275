import React from "react";
import { useHistory } from "react-router-dom";
import DeleteAlbumButton from "./dashboard/DeleteAlbumButton";

const DraftListView = ({ drafts }) => {
  let history = useHistory();

  const draftsList = drafts.map((draft) => (
    <li
      key={draft.id}
      className="flex items-center justify-between border-green-300"
    >
      {/* Clickable area for navigation */}
      <button
        className="flex-grow py-3 text-left text-gray-800 hover:bg-gray-700"
        onClick={() => {
          history.push({
            pathname: "/create/album",
            album: draft,
          });
        }}
      >
        <span className="ml-4 text-green-300 font-medium">{draft.name}</span>
      </button>

      {/* Delete button positioned to the far right */}
      <div className="pr-4">
        <DeleteAlbumButton album={draft} />
      </div>
    </li>
  ));

  return (
    <div className="my-4">
      <h2 className="text-xl font-theFutureBold text-green-300 pl-2 mb-3">
        Drafts
      </h2>
      <ul className="bg-black font-mono">{draftsList}</ul>
    </div>
  );
};

export default DraftListView;
