import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const db = firebase.firestore();
const getCurrentUser = () => firebase.auth().currentUser;

// Function to add or update a track in an album
async function manageTrack_old(album, track, operationType = "add") {
  const user = getCurrentUser();
  if (!user) throw new Error("No user logged in");

  const uid = user.uid;
  const tracksRef = db
    .collection("studio")
    .doc(uid)
    .collection("drafts")
    .doc(album.docId)
    .collection("tracks");

  const trackData = {
    index: track.index,
    track_name: track.trackName || "",
    filename: track.content || "",
    compressed_filename: track.compressed_filename || "",
    url: track.url || "",
    metadata: track.metadata || {},
    last_updated: firebase.firestore.Timestamp.now(),
  };

  if (operationType === "add") {
    const docRef = await tracksRef.add(trackData);
    return docRef.id; // Return new track ID
  } else if (operationType === "update") {
    await tracksRef.doc(track.id).update(trackData);
    return track.id; // Return existing track ID
  }
}




async function manageTrack(album, track, operationType = "add") {
  const user = getCurrentUser();
  if (!user) throw new Error("No user logged in");

  const uid = user.uid;
  const tracksRef = db
      .collection("studio")
      .doc(uid)
      .collection("drafts")
      .doc(album.docId)
      .collection("tracks");

  const trackData = {
      // Fill in track data fields
      index: track.index,
      track_name: track.trackName || "",
      filename: track.content || "",
      compressed_filename: track.compressed_filename || "",
      url: track.url || "",
      metadata: track.metadata || {},
      last_updated: firebase.firestore.Timestamp.now(),
  };

  if (operationType === "update") {
      const trackDoc = await tracksRef.doc(track.id).get();
      if (!trackDoc.exists) {
          throw new Error(`Track document ${track.id} does not exist.`);
      }
      await tracksRef.doc(track.id).update(trackData);
      return track.id;
  } else if (operationType === "add") {
      const docRef = await tracksRef.add(trackData);
      return docRef.id; // Return new track ID
  }
}
async function updateTrackWithTransaction(album, track) {
  return db.runTransaction(async (transaction) => {
      const trackRef = db.collection("studio")
                        .doc(album.userId)
                        .collection("drafts")
                        .doc(album.docId)
                        .collection("tracks")
                        .doc(track.id);

      const trackDoc = await transaction.get(trackRef);
      if (!trackDoc.exists) {
          throw new Error(`Track document ${track.id} does not exist.`);
      }

      const updatedTrackData = {
          // Fill in the updated track data fields
          index: track.index,
          track_name: track.trackName || "",
          filename: track.content || "",
          compressed_filename: track.compressed_filename || "",
          url: track.url || "",
          metadata: track.metadata || {},
          last_updated: firebase.firestore.Timestamp.now(),
      };

      transaction.update(trackRef, updatedTrackData);
  });
}


function updateAlbumIndices(album) {
  const user = getCurrentUser();
  if (!user) throw new Error("User is not signed in");

  const uid = user.uid;
  const batch = db.batch();
  var lastUpdated = firebase.firestore.Timestamp.now();
  album.songs.forEach((song, index) => {
    const songRef = db.doc(
      `studio/${uid}/drafts/${album.docId}/tracks/${song.id}`
    );
    batch.update(songRef, { index: index });
  });
//   update the album last updated timestamp
    const albumRef = db.doc(`studio/${uid}/drafts/${album.docId}`);
    batch.update(albumRef, { last_updated: lastUpdated });
  return batch.commit();
}

async function getAlbumDraft(album, callback) {
  const user = getCurrentUser();
  if (!user) throw new Error("User is not signed in");

  const uid = user.uid;
  const tracksRef = db
    .collection("studio")
    .doc(uid)
    .collection("drafts")
    .doc(album.docId)
    .collection("tracks");
  
  return tracksRef.orderBy("index", "asc").onSnapshot((querySnapshot) => {
    const draftData = processAlbumDraftSnapshot(querySnapshot);
    callback(draftData); // Execute the callback function to update state in the component
  });
}


function processAlbumDraftSnapshot(querySnapshot) {
  const tasks = {};
  const columnId = "column-2";
  const taskIds = [];

  querySnapshot.forEach((doc) => {
    const trackData = doc.data();
    tasks[doc.id] = {
      id: doc.id,
      content: trackData.filename,
      compressed_filename: trackData.compressed_filename,
      url: trackData.url,
      trackName: trackData.track_name,
      index: trackData.index,
      metadata: trackData.metadata,
    };
    taskIds.push(doc.id);
  });

  return {
    tasks,
    columns: { [columnId]: { id: columnId, title: "tracks", taskIds } },
    columnOrder: [columnId],
  };
}
function getFiles(callback) {
  const user = getCurrentUser();
  if (!user) {
    console.error("User is not signed in");
    return;
  }

  const uid = user.uid;
  const filesRef = db.collection("studio").doc(uid).collection("files");

  return filesRef.onSnapshot((querySnapshot) => {
    const fileData = processFilesSnapshot(querySnapshot);
    callback(fileData);
  });
}

function processFilesSnapshot(querySnapshot) {
  const tasks = {};
  const fileIds = [];

  querySnapshot.forEach((doc) => {
    const file = doc.data();
    tasks[doc.id] = {
      id: doc.id,
      content: file.filename,
      compressed_filename: file.compressed_filename,
      url: file.url,
    };
    fileIds.push(doc.id);
  });

  return {
    tasks,
    columns: {
      "column-1": { id: "column-1", title: "/files", taskIds: fileIds },
      "column-2": { id: "column-2", title: "tracks", taskIds: [] },
    },
    columnOrder: ["column-1", "column-2"],
  };
}

async function getDrafts() {
  var albums = [];
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var drafts_ref = db
          .collection(`studio/${uid}/drafts`)
          .orderBy("last_edited", "desc")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().is_published != true) {
                var _album = {
                  name: doc.data().name,
                  docId: doc.id,
                };
                albums.push(_album);
              }
            });
            resolve(albums);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
            reject(error);
          });
      }
    });
  });
}


// get tracks for a draft album
// get draft async function
async function getDraft(docId) {
  var album = { docId: docId };
  const tracks = await getRegularAlbumTracks(docId);
  console.log("tracks: ", tracks);
  const uid = localStorage.getItem("uid");
  return new Promise((resolve, reject) => {
    var drafts_ref = db
      .collection(`studio/${uid}/drafts`)
      .doc(docId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          album = {
            name: doc.data().name,
            artist_id: doc.data().artist_id,
            docId: doc.id,
            published: doc.data().published,
            last_edited: doc.data().last_edited,
            last_updated: doc.data().last_updated,
            tracks: tracks,
          };
          resolve(album);
        } else {
          console.log("No such document!");
          reject("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        reject(error);
      });

  });
}


async function getRegularAlbumTracks(docId) {
  return new Promise((resolve, reject) => {
    const uid = localStorage.getItem("uid");
    localStorage.setItem("uid", uid);
    ////("the docid: ", uid, "  ", album.docId);
    var album_tracks_ref = db
      .collection("studio")
      .doc(uid)
      .collection("drafts")
      .doc(docId)
      .collection("tracks");

    var tracks = [];

    album_tracks_ref.onSnapshot(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        var track = {
          compressed_filename: doc.data().compressed_filename,
          filename: doc.data().filename,
          index: doc.data().index,
          last_updated: doc.data().last_updated,
          track_name: doc.data().track_name,
          url: doc.data().url,
          metadata: doc.data().metadata,
        }
        tracks.push(track)
      });

      resolve(tracks);
    });
  
  });
}
// Exporting the functions
const drafting = {
  updateAlbumIndices,
  getRegularAlbumTracks,
  manageTrack,
  getDrafts,
  getDraft,
  getAlbumDraft,
  getFiles,
};

export default drafting;
