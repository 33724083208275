import React, { useState } from 'react';
import { useUploadFile } from '../../../file_mgmt/drive/hooks/useEventStorageHooks';
import { useProfile } from '../../../account/profile/context/ProfileContext';

const MemoryUploadView = ({ event }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { uploadMemory, uploadProgress, uploading, error } = useUploadFile(event);
  const { profileInfo } = useProfile(); // Use the profile information

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const filePreviews = files.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });

    Promise.all(filePreviews).then(previews => {
      setSelectedFiles(files.map((file, index) => {
        return { 
          data: file,
          preview: previews[index]
        };
      }));
    });
  };

  const handleUploadClick = async () => {
    for (const fileObj of selectedFiles) {
      // await uploadMemory(fileObj.data); // This will need to be adjusted to handle asynchronous uploads properly
      console.log("Uploading file:", fileObj.data);
      await uploadMemory(fileObj.data, profileInfo.id); // Adjust based on your implementation
    }
    setSelectedFiles([]); // Clear the selection after uploads
  };

  return (
    <div className="p-4">
      <input 
        id="file-upload" 
        type="file" 
        multiple
        className="hidden" 
        onChange={handleFileChange} 
        disabled={uploading}
      />
      <label htmlFor="file-upload" className="cursor-pointer flex flex-col items-center">
        <div className="flex flex-wrap justify-center gap-4 mb-4">
          {selectedFiles.length > 0 ? selectedFiles.map((file, index) => (
            <img key={index} 
                 src={file.preview} 
                 alt={`Preview ${index}`} 
                 className="w-20 h-20 object-cover rounded-md border border-gray-300" />
          )) : (
            <div className="w-20 h-20 flex items-center justify-center border border-dashed border-blue-500 rounded-md">
              <span className="text-blue-500">+</span>
            </div>
          )}
        </div>
        <span className="block text-sm font-semibold text-blue-500 hover:text-blue-700 transition duration-200">
          upload photos
        </span>
      </label>
      {selectedFiles.length > 0 && (
        <div className="mt-4 flex justify-center">
          <button 
            onClick={handleUploadClick} 
            disabled={uploading}
            className="px-6 py-2 rounded-full font-semibold transition-all duration-200 bg-blue-500 text-white hover:bg-blue-600 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </button>
        </div>
      )}
      {uploadProgress.map((progressInfo) => (
        <div key={progressInfo.id} className="mt-2">
          <div className="text-xs font-medium text-blue-700 text-center mb-1">{progressInfo.id}</div>
          <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
            <div className="bg-blue-600 h-1.5 rounded-full" style={{ width: `${progressInfo.progress}%` }}></div>
          </div>
        </div>
      ))}
      {error && <div className="text-red-600 mt-2">{error.message}</div>}
    </div>
  );
};

export default MemoryUploadView;
