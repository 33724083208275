import React from 'react';
import ImageFilter from 'react-image-filter';

const EventBanner = ({ banner_img }) => {
    const img = "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/test_uploads%2Fraphael_galatea.png?alt=media&token=a4d3b8ea-18dd-4298-b3ed-e7750542cedd";
  return (
    <div className="p-2 w-full">
      <ImageFilter
        image={img}
        filter={[
          1, 0.4, -0.6, 0, 0, 0, 1, 0, 0, 0, 1.3, 0, 1, 0, 0, 0, 0, 0, 1, 0,
        ]}
      />
    </div>
  );
};

export default EventBanner;
