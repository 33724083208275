import React from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory hook

const MemoryGrid = ({ memories, eventId }) => {
  const history = useHistory(); // Initialize useHistory hook

  // Function to navigate to the PhotoDetailView when an image is clicked
  const openPhotoDetail = (photoId) => {
    history.push(`/events/${eventId}/photos/${photoId}`); // Navigate to the photo's detail view
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
      {memories.map((memory, index) => (
        <div
          className="overflow-hidden rounded-lg shadow-lg cursor-pointer"
          key={memory.id} // It's better to use unique IDs than index if available
          onClick={() => openPhotoDetail(memory.id)} // Call openPhotoDetail with the memory's ID
        >
          <img src={memory.downloadURL} alt={`Memory ${index}`} className="w-full h-auto transform transition duration-300 hover:scale-105" />
        </div>
      ))}
    </div>
  );
};

export default MemoryGrid;
