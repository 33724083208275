import React, { useEffect, useState } from 'react';
import useEventMemories from '../../file_mgmt/drive/hooks/useEventStorageHooks';
import MemoryGrid from './MemoryGrid';
import MemoryUploadView from './upload/MemoryUploadView';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import { useParams } from 'react-router-dom';
import SignInButton from '../../../auth/SignInButton';

const EventMemoriesView = ({ event }) => {
  const [user, userLoading, userError] = useAuthState(firebase.auth());
  const { memories, loading, error } = useEventMemories(event.id);
  const { photoId } = useParams();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  useEffect(() => {
    if (photoId && memories.length > 0) {
      const index = memories.findIndex((memory) => memory.id === photoId);
      setSelectedImageIndex(index >= 0 ? index : null);
    }
  }, [photoId, memories]);

  const isLoading = loading || userLoading;
  const anyError = error || userError;

  if (isLoading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  if (anyError) {
    return <div className="text-red-600 p-4">Error: {anyError.message}</div>;
  }

  if (!user && memories.length > 0) {
    return (
      <div className="text-center p-4">
        <div className="text-lg text-gray-600">Sign in to view photos for this event.</div>
        <SignInButton />
      </div>
    );
  }

  return (
    <div>
      {user && <MemoryUploadView event={event} />}
      <MemoryGrid memories={memories} eventId={event.id} />
    </div>
  );
};

export default EventMemoriesView;
