import React from "react";
import events, { default as event_service } from "../../services/events";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import GridContainer from "../themes/GridContainer";
import ModalAuthView from "./ModalAuthView";

import ImageFilter from "react-image-filter";
import {
  MapPinIcon,
  CalendarIcon,
  InformationCircleIcon,
  CheckIcon,
  ClockIcon,
  LinkIcon,
} from "@heroicons/react/24/solid";
import { ArrowDownRightIcon } from "@heroicons/react/24/solid";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import APIConfig from "../../APIConfig";

import EventBanner from "./EventBanner";
import AttendingView from "./attending/AttendingView";
import EventDetailsView from "./EventDetailsView";
import GoingButtonView from "./GoingButtonView";
import EventToolbar from "../file_mgmt/drive/Toolbar/EventToolbar";
import EventMemoriesView from "./memories/EventMemoriesView";
import MemoriesButton from "./MemoriesButton";
import { WallProvider } from "../wall/contexts/WallContext";
import PostForm from "../wall/views/PostForm";
import PostList from "../wall/views/PostList";
import EventBannerImage from "./EventBannerImage";
import BetaDownloadButtonView from "../landing/BetaDownloadButtonView";
import OverlayedEventBannerImage from "./OverlayedEventBannerImage";

import RSVPButton from "./rsvp/RSVPButton";

const EventView = () => {
  const history = useHistory();
  let location = useLocation();
  var event_id = location.pathname.split("/")[2];
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEventData] = useState({});
  const [error, setError] = useState(null);
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [attendeeCount, setAttendeeCount] = useState(0);
  const [linkCopied, setLinkCopied] = useState(false);
  const [caption, setCaption] = useState("");

  const isUserAttending = () => {
    if (event && event.attending && user) {
      return event.attending.fs_uids.includes(user.uid);
    }
    return false;
  };

  // const formattedAddress = formatAddress(event.venue); // Formatting the address using the new function

  useEffect(() => {
    setLinkCopied(false);
    firebase.auth().onAuthStateChanged(async (user) => {
      setLoading(true);
      if (user) {
        setUser(user);
        var token = await firebase.auth().currentUser.getIdToken(true);
        setToken(token);
        const eventData = {
          id: event_id,
        };
        const e = await event_service.get(event_id, token);
        setAttendeeCount(e.attendee_count);
        console.log("current state data: ", eventData);
        setEventData(e);
  
        if (e === "no_name") {
          history.push({
            pathname: "/create_username_from_event",
            state: { eventData: eventData },
          });
        }

        setSignedIn(true);
        setLoading(false);
      } else {
        setSignedIn(false);
        setLoading(true);
        const e = await event_service.get_no_auth(event_id);
        setAttendeeCount(e.attendee_count);
        setEventData(e);
        setLoading(false);
      }
    });
  }, []);


  const getLinkCopyToast = () => {
    const t = toast(getToastText(), {
      duration: 1500,
      position: "bottom-center",
      style: getStyle(),
      className: "",
      icon: getEmoji(),
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });

    return t;
  };
  const getVenueAddressString = (venue) => {
    if (venue) {
      return [
        venue.name,
        venue.address.address_line_1,
        venue.address.address_line_2,
        venue.address.city,
        venue.address.state,
        venue.address.zip_code,
      ].join(" "); // You can use '\n' instead of ' ' if you want a line break between each part
    }
    return "dm for address";
  };

  const getToastText = () => {
    return "copied link to clipboard!";
  };
  const getEmoji = () => {
    return "🎉";
  };

  const getStyle = () => {
    return {
      border: "1px solid pink",
      backgroundColor: "black",
      padding: "16px",
      color: "pink",
      opacity: "0.5",
    };
  };

  function removeAttendee() {
    const uid = firebase.auth().currentUser.uid;
    setEventData((prevEvent) => ({
      ...prevEvent,
      attending: {
        ...prevEvent.attending,
        fs_uids: prevEvent.attending.fs_uids.filter((item) => item !== uid),
      },
    }));
    setAttendeeCount(attendeeCount - 1); // decrement attendee count
  }

  function addAttendee() {
    const uid = firebase.auth().currentUser.uid;
    setEventData((prevEvent) => ({
      ...prevEvent,
      attending: {
        ...prevEvent.attending,
        fs_uids: [...prevEvent.attending.fs_uids, uid],
      },
    }));
    setAttendeeCount(attendeeCount + 1); // increment attendee count
  }

  const removeCaption = () => {
    console.log("remove caption");
    setCaption("");
  };

  const getTime = (timeString) => {
    var time = new Date("1/1/2021 " + timeString);
    var hours = time.getHours();
    var minutes = time.getMinutes();
    // Prepend a 0 to hours if less than 10
    hours = hours < 10 ? "0" + hours : hours;
    // Prepend a 0 to minutes if less than 10
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes;
    console.log("formatted time (24hr): ", strTime);
    return strTime;
  };

  function getGoingButtonView() {
    return (
      //  {/* Sticky Footer */}
      <div className="bg-opacity-80  z-10 sticky bottom-3">
        {/* <BetaDownloadButtonView /> */}
        {signedIn ? (
          event ? (
            <RSVPButton
              className="mt-4" // Add margin top to separate from the download button
              decrement={removeAttendee}
              increment={addAttendee}
              event={event}
              token={token}
              removeCaption={removeCaption}
            />
          ) : (
            <div>Loading...</div>
          )
        ) : (
          <ModalAuthView event={event} className="mt-4" />
        )}
      </div>
    );
  }

  function presentEventCopySuccessToast() {
    setLinkCopied(true);
    getLinkCopyToast();
  }

  const getWallView = () => {
    if (signedIn) {
      return (
        <WallProvider eventId={event.id}>
          <PostForm />
          <div className=" pb-5">
            <PostList />
          </div>
        </WallProvider>
      );
    } else {
      return null; // or return some kind of placeholder or message for non-logged in users
    }
  };

  const loadAttendingView = () => {
    if (event) {
      if (event.attending) {
        return <AttendingView event={event} attendeeCount={attendeeCount} />;
      } else {
        return <div>loading...</div>;
      }
    } else {
      return <div>loading...</div>;
    }
  };

  const banner_img =
    "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/test_uploads%2Fraphael_galatea.png?alt=media&token=a4d3b8ea-18dd-4298-b3ed-e7750542cedd";

  function getEventElementView() {
    return (
      <div className="flex flex-col h-screen justify-between">
        <div className="overflow-auto">
          {/* Content goes here, minus the RSVP and Beta Download Button */}
          <OverlayedEventBannerImage
            eventId={event_id}
            eventName={event.name}
          />
          <EventDetailsView
            event={event}
            linkCopied={linkCopied}
            presentEventCopySuccessToast={presentEventCopySuccessToast}
            loadAttendingView={loadAttendingView}
            isAttending={isUserAttending()}
            eventTime={getTime(event.time)}
            address={getVenueAddressString(event.venue)}
          />
          {getWallView()}
          <EventMemoriesView event={event} />
        </div>

        {getGoingButtonView()}
      </div>
    );
  }

  if (loading) {
    return (
      <GridContainer>
        <div className="center vertical-center">
          <div className="horizontal-stack">
            <div className="dark:text-green-300 font-theFutureMonoRegular">
              loading...
            </div>
          </div>
        </div>
      </GridContainer>
    );
  } else {
    return (
      <div className="">
        {getEventElementView()}
        {/* <Toaster /> */}
      </div>
    );
  }
};

export default EventView;
