import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import storage from "../../../services/storage";
import TaskActions from "./TaskActions";
import TrackNameEditor from "./TrackNameEditor";
import useTrackEditor from "./useTrackEditor";
import TrackCellArtistView from "./TrackCellArtistView";
import TrackArtistEditor from "./TrackArtistEditor";

const TrackObjectCellContent = ({
  task,
  isEditing,
  startEditing,
  saveTrackName,
  cancelEditing,
}) =>
  isEditing ? (
    <TrackNameEditor
      trackName={task.trackName}
      onSave={saveTrackName}
      onCancel={cancelEditing}
    />
  ) : (
    <div className="cursor-pointer" onClick={startEditing}>
      <p className="font-theFutureBold text-lg ">{task.trackName}</p>
      <p className="text-gray-400 text-sm py-0.5">{task.content}</p>

    </div>
  );

const TrackObjectCell = ({
  task,
  index,
  album,
  column_id,
  styleVariant = "default",
}) => {
  const { isEditing, startEditing, cancelEditing, saveTrackName } =
    useTrackEditor(task, album, index);
  const [editMode, setEditMode] = useState(false);

  const cellStyle =
    styleVariant === "alternate"
      ? "bg-indigo-700 hover:bg-indigo-800"
      : "bg-gray-800 hover:bg-gray-900";
  const textStyle =
    styleVariant === "alternate" ? "text-indigo-200" : "text-gray-300";
  const handleUploadComplete = () =>
    console.log("Upload complete for track:", task.trackName);
  const draggableCellStyle = `${cellStyle} p-4 mb-3 rounded shadow transition duration-200 ease-in-out transform-gpu w-full min-w-0 min-h-[4rem]`; // Set a minimum height

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={`${draggableCellStyle} ${
            snapshot.isDragging ? "border-blue-500" : "border-transparent"
          } border-2`}
        >
          <div className={textStyle}>
            <TrackObjectCellContent
              task={task}
              isEditing={isEditing}
              startEditing={startEditing}
              saveTrackName={saveTrackName}
              cancelEditing={cancelEditing}
            />
            <TrackArtistEditor track={task} album={album} />
            <div className="mt-2">
              {editMode && (
                <TaskActions
                  onRename={startEditing}
                  onDelete={() =>
                    storage.deleteElement(task.id, column_id, album)
                  }
                  uploadComplete={handleUploadComplete}
                  track={task}
                  album={album}
                />
              )}
              <button
                onClick={() => setEditMode(!editMode)}
                className="p-1 rounded hover:bg-gray-700 bg-gray-600 text-gray-300 text-sm"
              >
                {editMode ? "Done" : "Edit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TrackObjectCell;
